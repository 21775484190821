<template>
  <div id="YahooStoreList">
    <TypeSelectVue :JumpRouteName="'YahooStoreList'"></TypeSelectVue>
    <template v-if="isMobile && $route.name === 'YahooStoreList'">
      <div class="MobileSelect">
        <el-select v-model="sortValue" placeholder="依条件排序">
          <el-option
            v-for="item in sortOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </template>
    <div class="MainContainer" :style="$route.name !== 'YahooStoreList' ? 'margin-bottom:0;' : ''">
      <template v-if="!isMobile">
        <div class="typeTitle">{{ labelType }}</div>
        <div class="lineRow"> </div>
      </template>

      <div class="filterRow" v-if="$route.name === 'YahooStoreList'">
        <template v-if="!isMobile">
          <div class="filterBlock">
            篩選條件:
            <el-select v-model="sortValue" placeholder="依条件排序">
              <el-option
                v-for="item in sortOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </template>
      </div>

      <div class="StoreItemBlock" v-if="$route.name === 'YahooStoreList'">
        <div
          class="StoreItem"
          v-for="item in storeItemsFilter"
          :key="item.id"
          @click="storeItemClick(item, 'YahooDetail')"
        >
          <div class="StoreImgBlock">
            <div class="StoreImgContain">
              <img :src="require(`@/assets/store/Yahoo/${item.id}.png`)" alt="" />
            </div>
          </div>
          <div class="StoreNameBlock" :title="item.name">
            {{ item.name }}
          </div>
          <div class="StorePriceBlock"> ￥{{ item.price }} </div>
        </div>
      </div>
    </div>

    <router-view :storeItems="storeItems"></router-view>
  </div>
</template>

<script>
  import base from './StoreListBase.js';
  export default {
    extends: base,
    name: 'YahooStoreList',
    created() {
      this.getCSV('/YH_Store.csv');
    },
    computed: {
      isMobile() {
        return this.$store.state.isMobile;
      },
    },
  };
</script>

<style lang="scss">
  #YahooStoreList {
    .el-select .el-input.is-focus .el-input__inner {
      border-color: unset;
    }
    .el-select .el-input__inner:focus {
      border-color: unset;
    }
    .MobileSelect {
      .el-select {
        margin-left: 8px;
        @media screen and(max-width:768px) {
          padding: 10px 40px !important;
          transform: translateX(-30px);
          background-color: #f4f4f4;
          margin-left: 0px;
          width: calc(100% + 30px);
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  @import './index.scss';
</style>
